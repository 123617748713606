.consultations-list {
    strong {
        font-size: 12px;
    }
    .list-type-1__title {
        color: $color-1--1;
    }
    .events-list {
        font-family: $typo-1;
        overflow: hidden;
        &__subtitle {
            margin-bottom: 1.5em;
        }
        &__wrapper {
            width: 70%;
            float: left;
            overflow: hidden;
            @include breakpoint(small down) {
                width: 100%;
                float: none;
                margin-bottom: 3rem;
            }
        }
        &__listitems {
            float: left;
            margin-right: 16%;
            &:last-child {
                margin-right: 0;
            }
            @include breakpoint(small down) {
                width: 15rem;
                margin-right: 1.5rem;
            }
        }
        &__item {
            font-size: 1.2em;
            padding-left: 10px;
            margin: 5px 0;
            position: relative;
            &:before {
                content: '';
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: $color-black;
                position: absolute;
                top: 48%;
                left: 0;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
        .button-1 {
            float: right;
            padding-top: 1.6em;
            padding-bottom: 1.6em;
            @include breakpoint(small down) {
                float: none;
            }
        }
    }
    .contacts {
        margin: 3rem 0;
        display: flex;
        justify-content: space-between;
        @include breakpoint(small down) {
            flex-direction: column;
        }
        &__item {
            margin: 1rem 1rem 1rem 0;
            vertical-align: middle;
            text-align: left;
            @include breakpoint(small down) {
                margin-right: 0;
            }
            strong, span, a {
                font-size: 1.2em;
                display: inline-block;
                vertical-align: middle;
                color: $color-black;
            }
        }
    }
}
