.enimages {
    @extend %bg-pattern-brown;
    background-color: #f3f3f3;
    padding: 2rem 0 4rem;
    &__wrap {
        margin: 5rem -1rem 0;
        display: flex;
        flex-wrap: wrap;
        @include breakpoint(medium down) {
            width: calc(100% - 12rem);
            margin: 5rem auto 0;
        }
        @include breakpoint(small down) {
            width: calc(100% - 6rem);
        }
    }
    &--lg {
        width: calc(50% - 1rem);
        .item {
            height: 100%;
        }
        @include breakpoint(medium down) {
            width: 100%;
        }
        @include breakpoint(small down) {
            width: calc(100% + 8rem);
            margin: 0 -4rem;
        }
    }
    &--sm {
        width: calc(50% + 1rem);
        display: flex;
        flex-wrap: wrap;
        @include breakpoint(medium down) {
            width: 100%;
            justify-content: space-between;
        }
        .item {
            width: calc(50% - 2rem);
            margin: 0 0 2rem 2rem;
            &:nth-child(n + 3) {
                margin-bottom: 0;
            }
            @include breakpoint(medium down) {
                width: calc(50% - 1rem);
                margin: 0;
            }
            @include breakpoint(small down) {
                width: 100%;
                &:nth-child(n + 3) {
                    margin-bottom: 3.5rem;
                }
            }
        }
    }
    .item {
        position: relative;
        display: block;
        color: $color-black;
        backface-visibility: hidden;
        overflow: hidden;
        @include breakpoint(medium down) {
            margin-bottom: 3.5rem;
        }
        &__img {
            width: 100%;
            height: 100%;
            padding-bottom: 76%;
            position: relative;
            overflow: hidden;
            @include breakpoint(medium down) {
                padding: 0;
                height: auto;
            }
            img {
                width: auto;
                height: auto;
                min-height: 100%;
                max-width: 100%;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: cover;
                font-family: 'object-fit: cover;';
                @include breakpoint(medium down) {
                    width: 100%;
                    position: static;
                    transform: translate(0, 0);
                }
            }
        }
        &__content {
            width: 90%;
            position: absolute;
            top: 100%;
            left: 50%;
            z-index: 10;
            transform: translate(-50%, -45%);
            opacity: 1;
            visibility: hidden;
            text-align: center;
            white-space: normal;
            transition: top 0.3s ease;
            @include breakpoint(medium down) {
                width: 100%;
                padding-top: 1.5rem;
                position: static;
                opacity: 1;
                visibility: visible;
                transform: translate(0, 0);
            }
            &-plus {
                margin: 0 auto 1rem;
                width: 4.2rem;
                height: 4.2rem;
                border-radius: 50%;
                background: $color-2--1;
                line-height: 4.2rem;
                @include breakpoint(medium down) {
                    display: none;
                }
                &:before {
                    content: '\e08a';
                    font-family: 'icons-default';
                    color: $color-white;
                    font-size: 2rem;
                }
            }
            &-category {
                font-size: 1.4rem;
                font-weight: $bold;
                text-transform: uppercase;
            }
            &-title {
                font-size: 2.4rem;
                font-weight: $normal;
                line-height: 1;
                @include breakpoint(small down) {
                    font-size: 2rem;
                }
            }
        }
        &--video {
            .item__img {
                @include default-icons-absolute-before('\e027', 1.6rem, $color-white, 50%, inherit, inherit, 50%, 'icons-default');
                &:before {
                    width: 4.7rem;
                    height: 4.7rem;
                    display: block;
                    z-index: 5;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    line-height: 4.7rem;
                    border-radius: 50%;
                    @include background-opacity($color-2--1, 0.9);
                }
            }
        }
        &:after {
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            display: block;
            top: 100%;
            left: 0;
            @include background-opacity($color-2--3, 0.5);
            opacity: 0;
            transition: all 0.25s ease;
            @include breakpoint(medium down) {
                content: none;
            }
        }
        &:hover,
        &:focus {
            text-decoration: none;
            .item__img {
                &:before {
                    content: none;
                }
            }
            &:after {
                top: 0;
                opacity: 1;
            }
            .item__content {
                top: 50%;
                opacity: 1;
                visibility: visible;
                transform: translate(-50%, -50%);
            }
            @include breakpoint(medium down) {
                .item__content {
                    transform: translate(0, 0);
                    &-title {
                        text-decoration: underline;
                    }
                }
            }
        }
        &--video {
            @include breakpoint(medium down) {
                &:hover,
                &:focus {
                    .item__img {
                        &:before {
                            content: '\e027';
                        }
                    }
                }
            }
        }
    }
}
