// @name Header page
.header {
    @extend %bg-pattern-brown;
    background-color: #f3f3f3;
    overflow: hidden;

    &__wrapper {
        @extend .wrapper-main;
        @extend .clear-fix;
        padding-top: 1.1em;
        position: relative;

        @include breakpoint(small down) {
            padding-bottom: 1rem;
        }
    }
    @include breakpoint(medium down) {
        min-height: 8.6rem;
        border-bottom: 3px solid #00aeef;
    }
}

// @name Top of content
.top-of-content {
    background: $color-3--1;
    @include breakpoint(small down) {
        display: none;
    }
}

.top-of-content__wrapper {
    @extend .wrapper-main;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
}

// @name Main wrapper
.main {
    width: 100%;
    clear: both;
}

// @name Main section
.section-main {

}

// @name Main section wrappers
.section-main__wrapper {
    @extend .wrapper-main;
    padding-top: 7em;
    padding-bottom: 3em;
    @include breakpoint(small down) {
        width: 100%;
        padding-top: 3em;
        padding-bottom: 3em;
    }
}

.section-main__wrapper-2 {
    display: table;
    width: 100%;
    @include breakpoint(small down) {
        display: flex;
        flex-direction: column;
    }
    > * {
        display: table-cell;
        vertical-align: top;
        @include breakpoint(small down) {
            display: block;
            vertical-align: inherit;
        }
    }
}

// @name Main section content
.section-main__content {
    width: 100%;
}

// @name Main section aside
.section-main__aside {
    > .column-bloc {
        *:first-child {
            margin-top: 0;
        }
    }
}

// @name Container
.container {
    @extend .wrapper-main;
}

// @name Footer
.footer {
    @extend %bg-pattern-blue;
    background-color: #144687;
    border-top: 3px solid $color-1--3;
    &__wrapper {
        @extend .wrapper-main;
        padding-top: 5rem;
        padding-bottom: 1rem;
        @include breakpoint(small down) {
            padding-top: 3.5rem;
        }
    }
    &__wrapper-2 {
        margin: 0 -1.5em;
        display: flex;
        width: 100%;
        @include breakpoint(small down) {
            margin: 0;
            display: block;
        }
        > * {
            @include breakpoint(small down) {
                flex: inherit;
                margin: 0 0 2em 0;
            }
        }
    }
}
