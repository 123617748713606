// @name List
.list {
    position: relative;
}

// @name List informations
.list__infos {
    margin: 3em 0;
    font-size: 1.6em;
    font-family: $typo-2;
    font-weight: $light;
    border-bottom: 1px solid $color-3--3;
    padding-bottom: em(2.5, 1.6);
    padding-right: em(4, 1.6);
    &:first-of-type {
        margin: 0 0 3em 0;
    }
}

// @name List RSS
.list__rss {
    position: absolute;
    top: -8px;
    right: 0;
}

// @name List letter
.list__title-1 {
    background: $color-1--2;
    color: $color-white;
    font-size: 2em;
    font-weight: $bold;
    padding: em(.5, 2) em(1, 2);
    margin-bottom: em(3, 2);
}
