// @name bloc event

// @name Bloc event in column
.column-bloc.bloc-event {
    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 2em;
    }
    .bloc-event__wrapper-date {
        margin-bottom: 2em;
    }
    .bloc-event__title {
        $bloc-event__title__font-size: 1.6;
        font-family: $typo-1;
        font-weight: $light;
        font-size: #{$bloc-event__title__font-size}em;
        margin: 0 0 em(1.5, $bloc-event__title__font-size);
        color: $color-3--4;
        a {
            color: $color-3--4;
        }
    }
}
