.bloc-offer {
    a {
        display: block;
        padding: 1.5rem 1.4rem 1.5rem 4.2rem;
        position: relative;
        color: $color-1--1;
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: $bold;
        background-color: $color-1--3;
        svg {
            width: 2.5rem;
            height: 2.5rem;
            position: absolute;
            left: 1.2rem;
            top: 50%;
            transform: translateY(-50%);
            fill: $color-1--1;
        }
    }
}
