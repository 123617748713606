// @name Search box
// @description Search module
.search-box {
    float: right;
    padding: 0;
    @include breakpoint(medium down) {
        width: auto;
        float: none;
    }

    form {
        margin: 0;
    }
    label {
        margin-right: em(0.5, 1.8);
        white-space: nowrap;
        font-size: 1.6rem;
        font-weight: $light;
        color: $color-3--4;
    }

    .search-box__fields-wrapper,
    .search-box__button-wrapper {
        display: inline-block;
        vertical-align: bottom;
    }
    .search-box__fields-wrapper {
        @include breakpoint(medium down) {
            width: 90%;
            margin: 0 auto;
            display: block;
        }
    }
    .search-box__button-wrapper {
        margin-left: -4.5rem;
        @include breakpoint(medium down) {
            margin-left: 0;
            position: absolute;
            right: 5%;
            bottom: 0;
        }
        .button-1 {
            background-color: transparent;
            height: 4.5rem;
            width: 4.5rem;
            @include breakpoint(medium down) {
                width: 4.9rem;
                height: 4.9rem;
            }
            &:before {
                width: 0.1rem;
                height: 2.5rem;
                background-color: $color-3--6;
                left: 0;
                content: '';
            }
            svg {
                fill: $color-1--1;
                @include breakpoint(medium down) {
                    width: 1.7rem;
                    height: 1.7rem;
                }
            }
            &:hover,
            &:focus {
                background-color: $color-1--3;
            }
        }
        //@include breakpoint(medium down) {
        //    padding-left: 1em;
        //}
    }

    // @name Search Field wrapper
    // @state .search__field-wrapper--in-line - Indicates label and field are side by side.
    .search-box__field-wrapper {
        &.search-box__field-wrapper--in-line {
            display: table;
            width: 100%;
            @include breakpoint(medium down) {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            label {
                display: table-cell;
                vertical-align: middle;
                text-align: right;
                padding-right: 1em;
                text-transform: none;
                @include breakpoint(medium down) {
                    text-align: left;
                }
            }
            input, select {
                display: table-cell;
                vertical-align: middle;
                width: 100%;
                min-width: 28.5rem;

                @include breakpoint(medium down) {
                    min-width: 100%;
                }
            }
            input {
                background-color: $color-white;
                border: 0;
                padding: 1.45rem 5rem 1.45rem 1rem;
                @include breakpoint(medium down) {
                    padding: 1.65rem 5rem 1.65rem 1rem;
                }
                @include placeholder() {
                    color: $color-1--1;
                    font-weight: $light;
                }
            }
        }
    }
    // Drop down menu
    .ddm {
        position: static;
        &.ddm--on {
            .ddm__title {
                display: none;
            }
        }
        > .ddm__sub-level {
            top: 0;
            right: 3em;
            left: 0;
            width: 100%;
            //@include breakpoint(medium down) {
            //    z-index: 102;
            //    padding: 1em;
            //    background: $color-3--2;
            //}
            @include breakpoint(medium down) {
                display: block;
                position: relative;
            }
        }
    }
}

// @name Search list
$pertinence__font-size: 1.2;
.pertinence {
    float: right;
    font-size: #{$pertinence__font-size}em;
    span {
        font-size: em(1, 1.2);
    }
}
.pertinence__metter {
    display: inline-block;
    vertical-align: middle;
    width: em(10, $pertinence__font-size);
    height: em(1, $pertinence__font-size);
    padding: em(.2, $pertinence__font-size);
    background: $color-3--2;
    margin: 0 em(1, $pertinence__font-size);
    > span {
        display: block;
        height: em(.8, $pertinence__font-size);;
        background: $color-1--2;
    }
}
