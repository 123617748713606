// @name site informations
.site-infos {
    &__img-wrap {
        float: left;
        margin-right: 4.5rem;
        @include breakpoint(medium down) {
            margin-right: 1.5rem;
            margin-left: 1.5rem;
        }
        @include breakpoint(small down) {
            float: none;
            margin-right: 0;
            margin-left: 0;
            text-align: center;
        }
    }
    &__wrapper {
        padding-top: 1rem;
        overflow: hidden;
        font-style: normal;
        display: flex;
        @include breakpoint(small down) {
            padding-top: 0;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }
    &__item {
        margin-right: 10rem;
        @include breakpoint(medium down) {
            margin-right: 1.5rem;
        }
        @include breakpoint(small down) {
            margin-right: 0;
            margin-top: 3.5rem;
        }
        &-phone,
        &-fax {
            &:before {
                font-family: 'icons-default';
                margin-right: 1rem;
                font-size: 1.3rem;
                color: $color-white;
                vertical-align: middle;
                @include breakpoint(small down) {
                    margin-right: 0.3rem;
                }
            }
        }
        &-phone {
            &:before {
                content: '\e02b';
            }
        }
        &-fax {
            &:before {
                content: '\e09f';
            }
        }
        &:first-child {
            p {
                &:first-child {
                    margin-top: 0;
                    margin-bottom: 0;
                    & + p {
                        margin-top: 0;
                    }
                }
            }
        }
    }
    p, ul {
        font-size: 1.3em;
        margin: em(.5, 1.3) 0 em(.8, 1.3);
        color: $color-white;
        &:first-child {
            margin-top: 0;
        }
    }
    p {
        font-size: 1.3rem;
        text-transform: uppercase;
        font-weight: $light;
    }
    ul {
        > li {
            margin: 0.2em 0;
        }
    }
    a {
        color: $color-white;
        text-decoration: none;
        &:hover, &:focus {
            text-decoration: underline;
        }
    }
    .button-1 {
        font-family: $typo-4;
        min-width: 16.1rem;
        margin-top: 0.5rem;
        color: $color-1--1;
        font-size: 1.2rem;
        background-color: $color-white;
        padding: em(1.35, $button-1__font-size) em(1.6, $button-1__font-size) em(1.35, $button-1__font-size) em(3, $button-1__font-size);
        &:before {
            left: 1.2rem;
            font-size: 1.4rem;
            content: '\e02a';
            color: $color-1--1;
        }
    }
}
