.ddm.ddm__content + .ddm.ddm__content {
    margin-top: -3.9em;
    .service-detail & {
        margin-top: 0;
    }
}

.ddm.ddm__content {
    margin: 4.5em 0 4.5em 0;
    .service-detail & {
        margin: 1.7rem 0;
    }
    .ddm__title {
        font-size: 1em;
        margin-bottom: 0;
    }
    .ddm__sub-level {
        position: static;
        top: inherit;
        right: inherit;
        z-index: inherit;
        display: block;
        height: auto;
        overflow: hidden;
        max-height: 0;
    }
    &.ddm--active {
        .ddm__sub-level {
            padding: 2.5rem 3rem 2.5rem 3.5rem;
            max-height: 100%;
            background: #fef8f6;
            .service-detail & {
                padding: 2rem;
                border: 1px solid #e8e7e7;
            }
            @include breakpoint(medium down) {
                padding: 1rem 2.5rem 2.5rem 3.5rem;
            }
        }
    }
}

.ddm__content {
    .ddm__button {
        display: block;
        width: 100%;
        text-align: left;
        font-size: 2.4rem;
        font-family: $typo-1;
        font-weight: $light;
        color: $color-3--4;
        background: $color-3--7;
        padding: em(1, 2) em(4, 2) em(1, 2) em(3, 2);
        position: relative;
        transition: all ease .3s;
        white-space: normal;
        @include breakpoint(medium down) {
            padding: 1.5rem 5.5rem 1.5rem 2.5rem;
            font-size: 1.6rem;
        }
        @include breakpoint(small down) {
            padding-left: 1rem;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 5rem;
            background: $color-3--4;
            z-index: 1;
        }
        @include default-icons-absolute-before('\e08a', 1.5rem, $color-white, 50%, 1.7rem, inherit, inherit);
        &:before {
            z-index: 2;
            transform: translateY(-50%);
            font-weight: $bold;
        }
        &:hover,
        &:focus {
            background: $color-1--1;
            color: $color-white;
            &:after {
                background: $color-1--3;
            }
        }
        .service-detail & {
            padding: 1.75rem 4rem 1.75rem 3.3rem;
            color: $color-1--1;
            font-weight: $normal;
            background-color: #efe7e4;
            &:before {
                content: '\e024';
                font-size: 1.8rem;
                color: $color-1--1;
                right: inherit;
                left: 1rem;
            }
            &:after {
                content: none;
            }
            &:hover,
            &:focus {
                background: $color-1--1;
                color: $color-white;
                &:before {
                    color: $color-white;
                }
            }
        }
    }
    &.ddm--active {
        .ddm__button {
            background: $color-1--1;
            color: $color-white;
            &:before {
                content: "\e023";
                color: $color-white;
                .service-detail & {
                    content: '\e025';
                }
            }
            &:after {
                background: $color-1--3;
            }
        }
    }
}
