// @name bloc publications

// @name Bloc publications in column and in go-so-far
.bloc-publications__picture {
    display: block;
    float: left;
    img {
        margin-right: 2em;
    }
}

.bloc-publications__wrapper {
    overflow: hidden;
}

.bloc-publications__title {
    $bloc-publications__title__font-size: 1.7;
    font-size: #{$bloc-publications__title__font-size}em;
    font-family: $typo-1;
    font-weight: $bold;
    text-transform: uppercase;
    line-height: 1;
    margin: 0 0 em(4, $bloc-publications__title__font-size);
    color: $color-1--1;
	@include breakpoint(medium down) {
        $bloc-publications__title__font-size: 1.6;
        font-size: #{$bloc-publications__title__font-size}em;
        margin: 0 0 em(.8, $bloc-publications__title__font-size);
	}
    a {
        color: $color-1--1;
        &:hover, &:focus {
            color: $color-1--2;
        }
    }
}

.column-bloc {
    .bloc-publications__title {
        $bloc-publications__title__font-size: 1.6;
        font-size: #{$bloc-publications__title__font-size}em;
        margin: 0 0 em(.8, $bloc-publications__title__font-size);
    }
}
