.logo {
    float: left;
    font-size: 1.3em;
    margin-left: -19.2rem;
    margin-top: 3rem;

    @include breakpoint(medium down) {
        margin: 0 4rem -1.7rem -6.3rem;

        img {
            width: 34rem;
        }
    }

    @include breakpoint(small down) {
        margin: 1rem 1rem -1.5rem -6.3rem;

        img {
            width: 23.7rem;
        }
    }
    a {
        display: block;
    }
}
