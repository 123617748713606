.medecins {
    .list__item {
        border-bottom: 2px solid $color-3--2;
        padding: 4em 0!important;
        &:first-child{
            padding-top: 0;
        }
    }
    .person__cv {
        margin-top: 1.5rem;
        a {
            display: block;
            font-size: 1.2rem;
            color: $color-1--1;
        }
    }
    .category {
        margin-bottom: 3px;
    }
    &-list {
        $medecins-list__size: 1;
        .list-infos__wrapper{
            .list-infos__listitems{
                flex: 1 1 0;
                &:first-child{
                    flex: 1 1 0;
                    padding-right: 10px;
                }
                & + .list-infos__listitems{
                    border-left: none;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
    &__list-services {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 2rem;
        @include breakpoint(small down) {
            display: block;
        }
    }
    .service-item {
        width: 46%;
        &__title {
            a {
                font-size: 18px;
                display: inline-block;
                color: $color-1--1;
                margin: 5px 0;
            }
        }
        .button-1 {
            font-size: 1.1rem;
        }
        @include breakpoint(small down) {
            width: 100%;
            margin-bottom: 3rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .date {
        a {
            color: $color-3--3;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
