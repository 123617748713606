// @name Main section heading
// @description Default css
$heading__h1__font-size: 4.5;
.heading {
    background: $color-3--1;
    padding: 3rem 0 3rem;
    &__wrapper {
        @extend .wrapper-main;
        display: flex;
        flex-wrap: nowrap;
        @include breakpoint(small down) {
            padding: 0 10px;
            display: block;
        }
        > *:last-child {
            margin-bottom: 0;
        }
        .heading__wrapper-figure {
            flex-basis: auto;
        }
        .heading__wrap {
            flex-basis: 100%;
        }
        &-title {
            @extend .wrapper-main;
            margin-bottom: 1.5rem;
        }
    }
    &__figure {
        margin-right: 2.5rem;
        display: table;
        @include breakpoint(medium down) {
            img {
                width: 32.7rem;
                max-width: inherit;
            }
        }
        @include breakpoint(small down) {
            width: 100%;
            float: none;
            margin-right: 0;
            margin-bottom: 2em;
            img {
                max-width: 100%;
                width: inherit;
            }
        }
    }
    &__picture {
        display: block;
        margin-bottom: .5em;
        img {
            //max-width: 100%;
            height: auto;
        }
    }
    &__caption {
        display: table-caption;
        caption-side: bottom;
    }
    h1 {
        font-family: $typo-1;
        font-weight: $bold;
        font-size: #{$heading__h1__font-size}rem;
        line-height: 1.1;
        color: $color-1--1;
        @include breakpoint(medium down) {
            font-size: 4rem;
        }
        @include breakpoint(small down) {
            font-size: 2.9rem;
        }
    }
    .teaser-2 {
        margin-top: 0;
    }
    h1 + .teaser-2 {
        margin-top: em(1, $teaser-2__font-size);
    }
    .subtitle {
        $category__font-size: 1.4;
        color: $color-1--2;
        font-size: #{$category__font-size}em;
        font-style: normal;
    }
    .subtitle + h1 {
        //margin-top: em(1.2, $heading__h1__font-size);
    }
    .date {
        font-size: 1.4em;
    }
    .status {
        $status__font-size: 1.5;
        background: #fff;
        font-size: #{$status__font-size}em;
        margin-top: em(0.5, $status__font-size);
        display: inline-block;
        &.status--new {
            background: $color-1--2;
            color: $color-white;
            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
        &.status--in-progress {
            background: $color-2--2;
            color: $color-white;
            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
    }
    .heading__media {
        margin-top: 1em;
        padding-top: 1em;
        border-top: 1px solid darken($color-3--1, 20%);
        .date, .media {
            font-size: 1.6em;
        }
        .media {
            font-weight: $bold;
            border: none;
            & + .date {
                &:before {
                    content: " - ";
                    display: inline;
                    font-size: 1.2em;
                    color: $color-3--3;
                }
            }
        }
    }
    // @name picture to right
    &.heading--picture-right {
        .heading__wrapper {
            .heading__wrapper-figure {
                order: 2;
            }
            .heading__wrap {
                order: 1;
            }
        }
        .heading__figure {
            margin-right: 0;
            margin-left: 2em;
            @include breakpoint(small down) {
                margin-left: 0;
            }
        }
    }
}

// @name Heading single news
// @dependency .heading
.heading--single-news {
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
            @include breakpoint(medium down) {
                width: auto;
                max-width: 100%;
            }
        }
    }
}

// @name Heading single event
// @dependency .heading
.heading--single-event {
    .heading__wrapper {
        display: block;
        flex-wrap: inherit;
    }
    .heading__figure {
        margin-right: 0;
    }
    .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
        display: table-cell;
        vertical-align: top;
    }
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
        }
    }
    .heading__wrapper-date {
        width: 16em;
        border-right: 1px solid $color-3--3;
        text-align: center;
    }
    .heading__wrapper-calendar-button {
        margin: 1em 1em 0;
        line-height: normal;
    }
    .heading__wrap {
        padding-left: 2em;
    }
    .button-1 {
        margin-top: 2em;
        cursor: pointer;
    }
}

// @name Heading single publications
// @dependency .heading
.heading--single-publications, .heading--single-public-market {
    .heading__picture {
        img {
            width: 220px;
            max-width: inherit;
            height: auto;
        }
    }
}

// @name Heading single directory
// @dependency .heading
.heading--single-directory {
    .list-infos {
        margin-top: 2em;
    }
    .list-infos__wrapper {
        .list-infos__listitems:first-child {
            flex: 1 1 0;
        }
    }
    .list-infos__item {
        padding-left: em(2, 1.6);
        font-size: 1.6em;
    }
}

// @name Heading single service
// @dependency .heading
.heading--single-service {
    .heading__wrapper {
        > * {
            flex-basis: 50%;
            padding-right: 3rem;
            @include breakpoint(small down) {
                padding-right: 0;
                margin-bottom: 3rem;
            }
        }
    }
    p {
        font-size: 1.5rem;
        line-height: 1.8;
        color: $color-3--4;
        & + h1 {
            margin-bottom: 1.5rem;
        }
    }
    .address {
        display: flex;
        align-items: center;
        position: relative;
        a {
            color: $color-1--1;
            padding-left: 8.5rem;
            &:hover,
            &:focus {
                color: $color-3--4;
                text-decoration: none;
                svg {
                    fill: $color-3--4;
                }
            }
        }
        &__icon {
            position: absolute;
            left: 0;
            top: 50%;
            width: 6rem;
            height: 6rem;
            padding: 1.2rem;
            transform: translateY(-50%);
            text-align: center;
            border: 2px solid $color-1--1;
            svg {
                width: 3.2rem;
                height: 3.2rem;
                fill: $color-1--1;
            }
            & + span {
                text-transform: uppercase;
            }
        }
        span {
            display: block;
            font-size: 1.8rem;
            line-height: 1.1;
            font-weight: $normal;
        }
    }
}

@include breakpoint(small down) {

    // @name Heading single event
    // @dependency .heading
    .heading--single-event {
        .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
            display: block;
            vertical-align: inherit;
        }
        .heading__picture {
            img {
                width: auto;
                max-width: 100%;
            }
        }
        .heading__wrapper-date {
            width: 100%;
            border-right: 0;
            display: table;
            border-bottom: 1px solid $color-3--3;
            margin-bottom: 2em;
            .date-1, .hour-place {
                display: table-cell;
                vertical-align: middle;
                width: 50%;
                padding-bottom: 2em;
            }
            .date-1 {
                text-align: left;
                time:nth-of-type(1) {
                    margin-left: 0;
                }
            }
        }
        .heading__wrap {
            padding-left: 0;
        }
    }
}
