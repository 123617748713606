// @name Go so far
// @description Sections content below the content

.go-so-far {
    background: $color-3--1;
    padding: 7em 0;
    @include breakpoint(small down) {
        padding: 6rem 0 4.5rem;
    }
    &__wrapper {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        margin: 0 -2%;
        @include breakpoint(small down) {
            flex-wrap: wrap;
        }
    }
    &__title {
        margin-top: 0;
    }
    .title-2 {
        @include breakpoint(small down) {
            margin: 3.8rem 0 3rem 0;
        }
    }
    &__item {
        flex: 1;
        margin: 0 2%;
        @include breakpoint(small down) {
            flex-basis: 100%;
        }
    }
}
