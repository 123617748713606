// @name bloc news

// @name Bloc news in column
.column-bloc.bloc-news {
    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 1em;
    }
    .bloc-news__title {
        $bloc-news__title__font-size: 1.6;
        font-family: $typo-3;
        font-weight: $light;
        font-size: #{$bloc-news__title__font-size}em;
        margin: 0 0 em(1.5, $bloc-news__title__font-size);
        color: $color-3--8;
        text-align: center;
        a {
            color: $color-3--8;
            &:hover, &:focus {
                color: $color-3--8;
            }
        }
    }
}
