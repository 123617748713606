// Color contrast
// Function that calculates if color needs to be light or dark.
@function color-contrast($color) {
    @return if(lightness($color) < 51, #FFF, #000);
}

// Transform Hexa to RGBA
@mixin background-opacity($color, $opacity: 0.5) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

// Calcul em size depending of the context
// Use em(fontSizeDoIWantInEm, fontContextInEM)
// padding: 0 em(3.5, 2.2) 0 em(5, 2.2);
@function em($fontSize, $context) {
    @return #{$fontSize/$context}em;
}

// Reset font icon
@mixin resetIcons() {
    &:before {
        font-family: none;
        content: none;
        margin: auto;
    }
}

// Create fontFace
@mixin fontFace($fontname, $fontfile, $fontweight: normal, $fontstyle: normal) {
    @font-face {
        font-family: "#{$fontname}";
        src: url("#{$path-fonts}#{$fontfile}.eot");
        src: url("#{$path-fonts}#{$fontfile}.eot?#iefix") format("embedded-opentype"),
        url("#{$path-fonts}#{$fontfile}.woff") format("woff"),
        url("#{$path-fonts}#{$fontfile}.ttf") format("truetype"),
        url("#{$path-fonts}#{$fontfile}.svg##{$fontname}") format("svg");
        font-weight: $fontweight;
        font-style: $fontstyle;
    }
}

// Add inline icon before
@mixin default-icons-before($icon, $margin: 0 5px 0 0, $size: 16px, $color: $color-black, $va: middle, $font: 'icons-default') {
    &:before {
        font-family: $font;
        content: $icon;
        margin: $margin;
        font-size: $size;
        color: $color;
        vertical-align: $va;
        display: inline-block;
        line-height: normal;
        text-transform: inherit;
    }
}

// Add inline icon after
@mixin default-icons-after($icon, $margin: 0 0 0 5px, $size: 16px, $color: $color-black, $va: middle, $font: 'icons-default') {
    &:after {
        font-family: $font;
        content: $icon;
        margin: $margin;
        font-size: $size;
        color: $color;
        vertical-align: $va;
        display: inline-block;
        line-height: normal;
        text-decoration: none;
    }
}

// Add absolute icon before
@mixin default-icons-absolute-before($icon, $size: 16px, $color: $color-black, $top: inherit, $right: inherit, $bottom: inherit, $left: inherit, $font: 'icons-default') {
    position: relative;
    &:before {
        font-family: $font;
        font-size: $size;
        color: $color;
        position: absolute;
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
        content: $icon;
        line-height: 1;
    }
}

// Add absolute icon after
@mixin default-icons-absolute-after($icon, $size: 16px, $color: $color-black, $top: inherit, $right: inherit, $bottom: inherit, $left: inherit, $font: 'icons-default') {
    position: relative;
    &:after {
        font-family: $font;
        font-size: $size;
        color: $color;
        position: absolute;
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
        content: $icon;
        line-height: 1;
    }
}

// Add image before
@mixin img-before($position: relative, $bgColor: transparent, $folderName: $path-images-bg, $fileName: $fileName, $width: auto, $height: auto, $zindex: 1, $top: inherit, $right: inherit, $bottom: inherit, $left: inherit) {
    position: $position;
    &:before {
        content: '';
        background: $bgColor url("#{$folderName}#{$fileName}") no-repeat 0 0;
        width: $width;
        height: $height;
        position: absolute;
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
        z-index: $zindex;
    }
}

// Add image after
@mixin img-after($position: relative, $bgColor: transparent, $folderName: $path-images-bg, $fileName: $fileName, $width: auto, $height: auto, $zindex: 1, $top: inherit, $right: inherit, $bottom: inherit, $left: inherit) {
    position: $position;
    &:after {
        content: '';
        background: $bgColor url("#{$folderName}#{$fileName}") no-repeat 0 0;
        width: $width;
        height: $height;
        position: absolute;
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
        z-index: $zindex;
    }
}

@mixin placeholder() {
    &::-webkit-input-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
}
