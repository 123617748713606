.header__bar {
    float: right;
    margin-bottom: 3rem;

    &-wrap {
        li {
            margin-right: 2.5rem;
            display: inline-block;
            vertical-align: middle;
            a {
                font-size: 1.1rem;
                text-transform: uppercase;
                color: #0c2a72;
                &:hover,
                &:focus {
                    color: $color-3--9;
                    text-decoration: none;
                }
                svg {
                    width: 2rem;
                    height: 2rem;
                    fill: $color-3--9;
                    vertical-align: middle;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

    &-picture {
        position: absolute;
        right: -18rem;
        top: 0;

        @include breakpoint(medium down) {
            display: block;
            position: static;
            right: 0;
        }
    }

    &-social {
        a {
            &:before {
                font-family: 'icons-default';
                content: '\e099';
                color: $color-3--9;
                font-size: 2rem;
            }
        }
    }
    @include breakpoint(medium down) {
        display: none;
    }
}
