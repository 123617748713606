@charset "UTF-8";

// Site factory testing
//@import "00-configuration/configuration-sitefactory";

// 00-configuration
@import "00-configuration/_configuration.scss";
@import "00-configuration/_mixins.scss";
@import "00-configuration/_framework.scss";
@import "00-configuration/_base.scss";
@import "00-configuration/_grillade.scss";
@import "00-configuration/_bp.scss";
// Desktop view [0 ; ∞]
// 01-utils
@import "01-utils/_reset.scss";
@import "01-utils/_styleguide.scss";
// 02-lib
@import "02-lib/_drop-down-menu.scss";
@import "02-lib/_access-tooltip.scss";
@import "02-lib/_accessible-autocomplete-list-aria.scss";
@import "02-lib/_tooltip-css.scss";
@import "02-lib/_fancybox.scss";
@import "02-lib/_datepicker.scss";
@import "02-lib/_jquery.timepicker.scss";
@import "02-lib/_service-public.scss";
// 03-partials
@import "03-partials/_forms.scss";
@import "03-partials/_buttons.scss";
@import "03-partials/_links.scss";
@import "03-partials/_wrappers.scss";
@import "03-partials/_layouts.scss";
@import "03-partials/_text.scss";
@import "03-partials/_heading.scss";
@import "03-partials/_titles.scss";
@import "03-partials/_pagers.scss";
@import "03-partials/_progressbar.scss";
@import "03-partials/_filters.scss";
@import "03-partials/_rte.scss";
@import "03-partials/_typo3.scss";
@import "03-partials/menus/_menu-skip.scss";
@import "03-partials/menus/_menu-stratis.scss";
@import "03-partials/menus/_menu-main.scss";
@import "03-partials/menus/_menu-cross.scss";
@import "03-partials/menus/_menu-main-1.scss";
@import "03-partials/lists/_lists.scss";
@import "03-partials/lists/_list-service.scss";
@import "03-partials/lists/_list-medecins.scss";
@import "03-partials/lists/_list-consultations.scss";
@import "03-partials/lists/_list-content.scss";
@import "03-partials/lists/_list-type-1.scss";
@import "03-partials/lists/_list-type-1--event.scss";
@import "03-partials/lists/_list-type-1--glossary.scss";
@import "03-partials/lists/_list-type-1--poll.scss";
@import "03-partials/lists/_list-type-2.scss";
@import "03-partials/lists/_list-type-2--publications.scss";
@import "03-partials/lists/_list-type-3.scss";
@import "03-partials/lists/_list-type-4.scss";
@import "03-partials/singles/_single.scss";
@import "03-partials/singles/_single--news.scss";
@import "03-partials/singles/_single--poll.scss";
@import "03-partials/objects/_breadcrumb.scss";
@import "03-partials/objects/_tools.scss";
@import "03-partials/objects/_cookies.scss";
@import "03-partials/objects/_logo.scss";
@import "03-partials/objects/_stratis.scss";
@import "03-partials/objects/_page-number.scss";
@import "03-partials/objects/_form-navigation.scss";
@import "03-partials/objects/_galeries.scss";
@import "03-partials/objects/_parallax.scss";
@import "03-partials/objects/_videos.scss";
@import "03-partials/objects/_click-roll.scss";
@import "03-partials/objects/_go-so-far.scss";
@import "03-partials/objects/_popin-homepage.scss";
@import "03-partials/objects/_print-banner.scss";
@import "03-partials/objects/_newsletter.scss";
@import "03-partials/objects/_search.scss";
@import "03-partials/objects/_go-to-top.scss";
@import "03-partials/objects/_subpages.scss";
@import "03-partials/objects/_sitemap.scss";
@import "03-partials/objects/_list-document.scss";
@import "03-partials/objects/_list-infos.scss";
@import "03-partials/objects/_comments.scss";
@import "03-partials/objects/_site-infos.scss";
@import "03-partials/objects/_bloc-news.scss";
@import "03-partials/objects/_bloc-event.scss";
@import "03-partials/objects/_bloc-publications.scss";
@import "03-partials/objects/_bloc-rapides.scss";
@import "03-partials/objects/_bloc-directory.scss";
@import "03-partials/objects/_bloc-contact.scss";
@import "03-partials/objects/_bloc-offer.scss";
@import "03-partials/objects/_service-detail.scss";
@import "03-partials/objects/_header-bar.scss";
@import "03-partials/home/_banner.scss";
@import "03-partials/home/_offre.scss";
@import "03-partials/home/_rapides.scss";
@import "03-partials/home/_agenda.scss";
@import "03-partials/home/_news.scss";
@import "03-partials/home/_enimages.scss";
@import "03-partials/home/_joinus.scss";
// 05-shame
@import "05-shame/_shame.scss";

/*
@media (max-width: #{$tablet}px) {
    // 01-utils
    @import "02-tablet/01-utils/_reset.scss";
    @import "02-tablet/01-utils/_styleguide.scss";

    // 02-lib
    @import "02-tablet/02-lib/_fancybox.scss";

    // 03-partials
    @import "02-tablet/03-partials/_forms.scss";
    @import "02-tablet/03-partials/_buttons.scss";
    @import "02-tablet/03-partials/_wrappers.scss";
    @import "02-tablet/03-partials/_heading.scss";
    @import "02-tablet/03-partials/_pagers.scss";
    @import "02-tablet/03-partials/_rte.scss";
    @import "02-tablet/03-partials/menus/_menu-skip.scss";
    @import "02-tablet/03-partials/menus/_menu-stratis.scss";
    @import "02-tablet/03-partials/menus/_menu-main-1.scss";
    @import "02-tablet/03-partials/lists/_list-type-1.scss";
    @import "02-tablet/03-partials/lists/_list-type-1--event.scss";
    @import "02-tablet/03-partials/lists/_list-type-2.scss";
    @import "02-tablet/03-partials/lists/_list-type-3.scss";
    @import "02-tablet/03-partials/objects/_search.scss";
    @import "02-tablet/03-partials/objects/_cookies.scss";
    @import "02-tablet/03-partials/objects/_go-so-far.scss";
    @import "02-tablet/03-partials/objects/_galeries.scss";
    @import "02-tablet/03-partials/objects/_list-document.scss";
    @import "02-tablet/03-partials/objects/_list-infos.scss";
    @import "02-tablet/03-partials/objects/_comments.scss";
    @import "02-tablet/03-partials/objects/_bloc-publications.scss";

    // 05-shame
    @import "02-tablet/05-shame/_shame.scss";
}*/
