$service-list__size: 1;
.service-list {
    .filters {
        background: $color-3--1;
    }
    &__content {
        font-family: $typo-2;
        .service-item {
            border-top: 1px solid $color-3--2;
            padding-top: 40px;
            margin-bottom: 30px;
            &:first-child {
                border-top: none;
                padding-top: 0;
            }
        }
        .subtitle {
            font-size: em(1.8, $service-list__size);
            font-weight: $bold;
            text-transform: none;
            @include breakpoint(small down) {
                font-size: em(1.4, $service-list__size);
            }
        }
        h2 {
            font-size: em(3.2, $service-list__size);
            @include breakpoint(small down) {
                font-size: em(2, $service-list__size);
            }
            a {
                color: $color-1--2;
            }
        }
        a {
            color: $color-black;
        }
        p {
            font-size: em(1.2, $service-list__size);
            margin-bottom: 7px;
        }
        .category {
            font-size: em(1.2, $service-list__size);
            text-transform: none;
        }
        .service__row {
            text-align: justify;
            margin-top: 40px;
            &:after {
                content: '';
                display: inline-block;
                width: 100%;
            }
        }
        .col {
            display: inline-block;
            vertical-align: top;
            margin-right: 15px;
            @include breakpoint(small down) {
                display: block;
                margin: 0 0 30px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .button-1 {
            text-align: center;
            font-weight: $medium;
            padding: em(1, $service-list__size) 15px;
            color: $color-white;
            &:hover,
            &:focus {
                color: $color-1--1;
            }
        }
        .col-big-btn {
            .button-1 {
                background: $color-2--1;
                padding: em(2, $service-list__size) 15px;
            }
        }
    }
}
