.joinus {
    margin: 4.5rem 0;
    &__wrap {
        margin: 0 -1rem;
        display: flex;
        justify-content: space-between;
        @include breakpoint(medium down) {
            margin: 0;
        }
        @include breakpoint(small down) {
            flex-direction: column;
        }
    }
}

.rejoindre {
    margin-right: 3rem;
    flex-shrink: 0;
    @include breakpoint(small down) {
        margin-right: 0;
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        @include breakpoint(small down) {
            margin-bottom: 3.5rem;
            padding: 0 2rem;
        }
    }
    &__image {
        width: 26.3rem;
        height: 22rem;
        @include breakpoint(medium down) {
            width: 19.1rem;
            height: 16rem;
        }
        @include breakpoint(small down) {
            width: 100%;
            height: auto;
        }
        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }
    }
    &__links {
        max-width: 30rem;
        @include breakpoint(medium down) {
            text-align: center;
            max-width: 18.3rem;
        }
        @include breakpoint(small down) {
            width: 100%;
            max-width: 100%;
        }
        a {
            display: flex;
            padding: 0 5.8rem;
            align-items: center;
            height: 50%;
            color: $color-white;
            font-size: 1.8rem;
            font-weight: $bold;
            line-height: 1;
            @include breakpoint(medium down) {
                padding: 0 2rem;
                justify-content: center;
            }
            @include breakpoint(small down) {
                padding: 4rem 1rem;
            }
            svg {
                width: 3rem;
                height: 3rem;
                margin-right: 0.7rem;
                fill: $color-white;
                vertical-align: middle;
                @include breakpoint(medium down) {
                    display: none;
                }
                @include breakpoint(small down) {
                    display: block;
                }
            }
            &:first-child {
                background-color: #0986b5;
            }
            &:last-child {
                background-color: $color-1--2;
            }
        }
    }
}

.kiosque {
    .bloc-publications__picture {
        @include breakpoint(medium down) {
            img {
                width: 11.4rem;
                margin-right: 3.5rem;
            }
        }
        @include breakpoint(small down) {
            float: none;
            text-align: center;
            img {
                width: 15.7rem;
                margin: 0 0 1rem 0;
            }
        }
    }
    .bloc-publications__title {
        @include breakpoint(small down) {
            text-align: center;
        }
    }
    .list-document-1__listitems {
        @include breakpoint(small down) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
    .list-document-1__item {
        position: relative;
        color: $color-white;
        transition: all 0.3s ease;
        &:before {
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
            color: $color-white;
            transition: all 0.3s ease;
        }
        a {
            background-color: $color-1--1;
            display: inline-block;
            padding: 1rem 1rem 1rem 3rem;
            font-size: 1.2rem;
            font-weight: $light;
            color: $color-white;
        }
        &:hover,
        &:focus {
            &:before {
                color: $color-1--1;
            }
            a {
                background-color: $color-1--3;
                text-decoration: none;
            }
        }
    }
}
