// @name Wrapper with wide 940px
.wrapper-main {
    width: 100%;
    max-width: 96rem;
    margin: 0 auto;
    padding: 0 1rem;
    @extend .clear-fix;
	// @name Wrapper with wide 940px
	@include breakpoint(medium down) {
        width: 100%;
        max-width: 96rem;
	}
}

// @name Wrapper with wide 980px
.wrapper-menu-main {
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
    padding: 0 1rem;
    @extend .clear-fix;
    // @name Wrapper with wide 940px
    @include breakpoint(medium down) {
        width: 100%;
        max-width: 96rem;
        padding: 0;
        margin: 0;
    }
}

// @name Background-in-wrapper
// @description Displayed in grey background on the sides.
.background-in-wrapper {
    position: relative;
    background: $color-3--1;
    @include breakpoint(small down) {
        margin: 0 -1em;
        padding: 0 1em;
    }
    &:before,
    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        width: 999em;
        height: 100%;
        background: $color-3--1;
        @include breakpoint(small down) {
            width: 0;
        }
    }
    &:before {
        left: -9990px;
    }
    &:after {
        right: -9990px;
    }
    // @description Displayed in dark background on the sides.
    &.background-in-wrapper--dark {
        background: $color-3--3;
        &:before,
        &:after {
            background: $color-3--3;
        }
    }
}

// @name Column bloc
// @description Generic bloc in .section-main__aside
$column-bloc__width: 24;
$column-bloc__padding-left: 6;
.column-bloc {
    width: #{$column-bloc__width}em;
    padding-left: #{$column-bloc__padding-left}em;
    margin-bottom: 5.5em;
    @include breakpoint(medium down) {
        $column-bloc__padding-left: 5;
        padding-left: #{$column-bloc__padding-left}em;
    }
    @include breakpoint(small down) {
        width: 20rem;
        margin: 0 auto 5.5rem;
        padding-left: 0;
    }
}

.column-bloc__wrapper {
    padding: 0;
}
