// @name Liste type 3
// @description Directory list
.list-type-3 {
    margin-bottom: 5em;
    @extend .clear-fix;
}

.list-type-3__listitems {
    .list-paginated__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 -2%;
        @include breakpoint(small down) {
            flex-direction: column;
            margin: 0;
        }
        > article {
            flex: 0 1 46%;
            max-width: 46%; // Trick ie11 for debug padding
            margin: 0 2% 3em;
            @include breakpoint(small down) {
                flex: 0 1 100%;
                max-width: inherit;
                margin: 0 0 3em;
            }
        }
    }
}

.list-type-3__item {
    background: $color-3--1;
    padding: 40px 28px;
    position: relative;
    &:before,
    &:after {
        content: "";
        speak: none;
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        top: 0;
        right: 0;
    }
    &:after {
        border-width: 38px 0 0 38px;
        border-color: transparent transparent transparent $color-3--2;
    }
    &:before {
        border-width: 0 38px 38px 0;
        border-color: transparent $color-white transparent transparent;
    }
}

.list-type-3__picture {
    display: block;
    float: left;
    @include breakpoint(medium down) {
        float: none;
    }
    img {
        margin-right: 1em;
        max-width: 120px;
        height: auto;
        @include breakpoint(medium down) {
            margin-right: 0;
            margin-bottom: 1em;
            max-width: 100%;
            height: auto;
        }
    }
}

.list-type-3__wrapper {
    overflow: hidden;
}

.list-type-3__title {
    font-family: $typo-3;
    color: $color-black;
    font-size: 2em;
    font-weight: $bold;
    margin: .2em 0 .8em;
    a {
        color: $color-black;
    }
}
