// @name Title 1
// @description Styling for title 1
$title--1__font-size: 2.6;
.title-1 {
    font-family: $typo-1;
    font-size: #{$title--1__font-size}em;
    line-height: 1em;
    font-weight: $normal;
    text-transform: uppercase;
    color: $color-3--4;
    margin: em(3, $title--1__font-size) 0 em(2, $title--1__font-size);
    padding-left: 4.3rem;
    position: relative;
    svg {
        width: 3.6rem;
        height: 3.6rem;
        position: absolute;
        left: 0rem;
        top: -1.3rem;
    }
    &:before {
        width: 5.4rem;
        height: 0.1rem;
        content: '';
        position: absolute;
        top: 2.2rem;
        left: -3.4rem;
        background-color: red;
    }
    .home & {
        @include breakpoint(small down) {
            padding-left: 2.8rem;
            font-size: 2rem;
            svg {
                width: 2.2rem;
                height: 2.2rem;
                top: -0.5rem;
            }
            &:before {
                top: 1.7rem;
                left: -5rem;
            }
        }
    }
    @include breakpoint(small down) {
        $title--1__font-size: 2.2;
        font-size: #{$title--1__font-size}em;
        margin: em(2.5, $title--1__font-size) 0 em(1.5, $title--1__font-size);
    }
}

// @name Title 2
// @description Styling for title 2
$title--2__font-size: 1.8;
.title-2 {
    font-family: $typo-1;
    font-size: #{$title--2__font-size}em;
    font-weight: $bold;
    text-transform: uppercase;
    margin: em(6, $title--2__font-size) 0 em(2.5, $title--2__font-size);
    color: $color-3--4;
}

// @name Title 3
// @description Styling for title 3
$title--3__font-size: 2.2;
.title-3 {
    font-family: $typo-1;
    font-size: #{$title--3__font-size}em;
    line-height: 1em;
    font-weight: $normal;
    text-transform: uppercase;
    color: $color-3--4;
    margin: em(2.5, $title--3__font-size) 0 em(1.1, $title--3__font-size);
    padding-left: 4rem;
    position: relative;
    svg {
        width: 2.6rem;
        height: 2.6rem;
        position: absolute;
        left: 0.5rem;
        top: -0.7rem;
    }
    &:before {
        width: 5.4rem;
        height: 0.1rem;
        content: '';
        position: absolute;
        top: 1.7rem;
        left: -3.4rem;
        background-color: red;
    }
}

// @name Title decoration
// @description Styling for title decoration
.title {
    &--blue {
        svg {
            fill: $color-1--1;
        }
        &:before {
            background-color: $color-1--1;
        }
    }
    &--orange {
        svg {
            fill: $color-2--1;
        }
        &:before {
            background-color: $color-2--1;
        }
    }
    &--lightblue {
        svg {
            fill: $color-1--3;
        }
        &:before {
            background-color: $color-1--3;
        }
    }
    &--darkblue {
        svg {
            fill: $color-1--4;
        }
        &:before {
            background-color: $color-1--4;
        }
    }
}

// @name Title on home page
// @description Styling for title on home page
.home-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include breakpoint(small down) {
        flex-direction: column;
        align-items: flex-start;
    }
    .link-view {
        margin-top: 1rem;
        @include breakpoint(small down) {
            margin: 0 -0.5rem;
        }
        &__feature {
            display: inline-block;
            margin-right: 1rem;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
