.rapides {
    padding: 6rem 0 3rem;
    background-color: $color-1--1;
    .container {
        position: relative;
    }
    &__wrap {
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    &__item {
        text-align: center;
        svg {
            width: 5.8rem;
            height: 5.1rem;
            margin-bottom: 1rem;
            fill: $color-white;
        }
        a {
            margin: 0 5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $color-white;
            font-size: 1.6rem;
            white-space: normal;
        }
    }

    .prevCarrousel2,
    .nextCarrousel2 {
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translatey(-50%);

        button {
            width: 5rem;
            height: 5rem;
            position: relative;
            @include background-opacity($color-1--3, 0.5);
            text-indent: -9999px;
            overflow: hidden;
            transition: all 0.3s ease;
            border-radius: 50%;
            &:before {
                font-size: 1.6rem;
                text-indent: 0;
                font-family: 'icons-default';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: rgba(255, 255, 255, 0.65);
                transition: all 0.3s ease;
            }
        }
        &:hover,
        &:focus {
            button {
                @include background-opacity($color-1--3, 0.9);
                &:before {
                    color: $color-white;
                }
            }
        }
    }
    .prevCarrousel2 {
        left: 0;
        button {
            &:before {
                content: '\e01a';
            }
        }
    }
    .nextCarrousel2 {
        right: 0;
        button {
            &:before {
                content: '\e01b';
            }
        }
    }
}
