.banner {
    position: relative;
    &__item {
        height: 53rem;
        width: 100%;
        position: relative;
        z-index: 5 !important;
        @include breakpoint(medium down) {
            height: 34rem;
        }
        @include breakpoint(small down) {
            height: auto;
        }
        &:before {
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            background-image: url('../images/temp/bg-banner.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }
        &:hover,
        &:focus {
            .banner__info {
                h2 {
                    text-decoration: underline;
                }
            }
        }
    }
    &__info {
        width: 40rem;
        padding: 1.8rem 2.5rem 2.9rem 3rem;
        position: absolute;
        right: 33rem;
        bottom: 0;
        background-color: $color-1--1;
        @include breakpoint(medium down) {
            right: 2rem;
        }
        @include breakpoint(small down) {
            position: relative;
            right: 0;
            width: 100%;
            z-index: 11;
        }
        p,
        h2 {
            color: $color-white;
        }
        p {
            margin-bottom: 1rem;
            position: relative;
            font-size: 1.2rem;
            font-weight: $bold;
            text-transform: uppercase;
            font-style: italic;
            &:before {
                width: 3.9rem;
                height: 0.1rem;
                content: '';
                position: absolute;
                bottom: 0.3rem;
                left: -4.1rem;
                background-color: #e56404;
            }
        }
        h2 {
            font-size: 2.6rem;
            font-weight: $light;
            line-height: 1.1;
        }
    }
    .pagerCarrousel1 {
        display: none;
    }
    .modeplay {
        width: 2rem;
        height: 2rem;
        position: absolute;
        left: calc(50% + 1rem);
        bottom: -3.5rem;
        z-index: 12;
        text-indent: -9999px;
        overflow: hidden;
        transition: all 0.3s ease;
        @include breakpoint(medium down) {
            display: none;
        }
        span {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            &:before {
                content: '\e076';
                font-size: 1.6rem;
                text-indent: 0;
                font-family: 'icons-default';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $color-3--3;
                transition: all 0.3s ease;
            }
        }
        &--pause {
            left: calc(50% - 1rem);
            span {
                &:before {
                    content: '\e075';
                }
            }
        }
        &:hover,
        &:focus {
            span {
                &:before {
                    color: $color-1--4;
                }
            }
        }
    }
    .prevNextCarrousel1 {
        @include breakpoint(medium down) {
            display: none;
        }
    }
    .prevCarrousel1,
    .nextCarrousel1 {
        position: absolute;
        z-index: 9;
        top: 50%;
        transform: translatey(-50%);

        button {
            width: 5rem;
            height: 5rem;
            position: relative;
            @include background-opacity($color-white, 0.4);
            text-indent: -9999px;
            overflow: hidden;
            transition: all 0.3s ease;
            border-radius: 50%;
            &:before {
                font-size: 1.6rem;
                text-indent: 0;
                font-family: 'icons-default';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $color-white;
                transition: all 0.3s ease;
            }
        }
        &:hover,
        &:focus {
            button {
                @include background-opacity($color-1--3, 0.4);
                &:before {
                    color: $color-white;
                }
            }
        }
    }
    .prevCarrousel1 {
        left: 1.5rem;
        button {
            &:before {
                content: '\e01a';
            }
        }
    }
    .nextCarrousel1 {
        right: 1.5rem;
        button {
            &:before {
                content: '\e01b';
            }
        }
    }
}
