.news {
    margin: 5.5rem 0 3rem;
    overflow: hidden;

    &__container {
        position: relative;
    }
    &__wrap {
        margin: 2.5rem -0.7rem 0;
        display: flex;
        justify-content: space-between;
        @include breakpoint(medium down) {
            width: 90%;
            margin: 2.5rem auto 0;
        }
        @include breakpoint(small down) {
            width: 27rem;

        }
    }
    &__item {
        text-align: center;
        vertical-align: top;
        &-wrap {
            @include breakpoint(small down) {
                margin: 0 2rem;
            }
        }
        img {
            @include breakpoint(small down) {
                width: 100%;
                height: auto;
            }
        }
        &-content {
            max-width: 30rem;
            margin: 0 auto;
            padding: 2rem;
            .category {
                font-size: 1.2rem;
                color: $color-1--2;
                font-style: normal;
            }
            .title {
                color: $color-3--4;
                font-size: 1.6rem;
                font-weight: $light;
                white-space: normal;
                a {
                    color: $color-3--4;
                }
            }
        }
    }
    .prevCarrousel3,
    .nextCarrousel3 {
        position: absolute;
        z-index: 10;
        top: 35%;
        transform: translatey(-50%);
        @include breakpoint(small down) {
            top: 30%;
        }
        button {
            width: 5rem;
            height: 5rem;
            position: relative;
            text-indent: -9999px;
            overflow: hidden;
            transition: all 0.3s ease;
            border-radius: 50%;
            &:before {
                font-size: 2.3rem;
                text-indent: 0;
                font-family: 'icons-default';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #0c2a72;
                transition: all 0.3s ease;
            }
        }
        &:hover,
        &:focus {
            button {
                &:before {
                    color: $color-1--3;
                }
            }
        }
    }
    .prevCarrousel3 {
        left: -5rem;
        @include breakpoint(medium down) {
            left: 0.5rem;
        }
        @include breakpoint(small down) {
            left: -2.5rem;
        }
        button {
            &:before {
                content: '\e01a';
            }
        }
    }
    .nextCarrousel3 {
        right: -5rem;
        @include breakpoint(medium down) {
            right: 0.5rem;
        }
        @include breakpoint(small down) {
            right: -2.5rem;
        }
        button {
            &:before {
                content: '\e01b';
            }
        }
    }
}
