// @name buttons

// @name button-1 (light)
// @state button-1--big - Big button-1
// @state button-1--small - Small button-1
// @state .button-1--no-icon - Button-1 without icon
// @state .button-1--svg - Button-1 with svg content
// @state .button-1--no-text-in-mobile - Indicates button-1 without content text in smartphone view
// @state .button-1--variation-1 - Button-1 with variation-1 (bold, color white, background red)

$button-1__font-size: 1.2;
$button-1--big__font-size: 1.9;
$button-1--small__font-size: 1.2;
input.button-1, .rte input.button-1 {
    padding-left: em(3.2, $button-1__font-size);
    &.button-1--big {
        padding-left: em(3.2, $button-1--big__font-size);
    }
    &.button-1--small {
        padding-left: em(2.2, $button-1--small__font-size);
    }
}

.button-1,
.rte .button-1 {
    font-family: $typo-1;
    display: inline-block;
    vertical-align: middle;
    transition: all ease .3s;
    text-decoration: none;
    font-weight: $bold;
    color: $color-white;
    text-transform: uppercase;
    background: $color-1--2;
    font-size: #{$button-1__font-size}em;
    line-height: em(1, $button-1__font-size);
    padding: em(1.55, $button-1__font-size) em(2.3, $button-1__font-size) em(1.55, $button-1__font-size) em(3.65, $button-1__font-size);
    @include default-icons-absolute-before('\e026', 1.1rem, $color-white, 50%, inherit, inherit, 2.2rem);
    &.button-1--no-text-in-mobile {
        @include breakpoint(medium down) {
            text-align: left;
            text-indent: -9999px;
            white-space: nowrap;
            position: relative;
            padding: em(1.3, $button-1__font-size) em(2.1, $button-1__font-size);
            &:before {
                text-indent: 0;
                line-height: 0;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                margin: 0;
                font-size: em(1.4, $button-1__font-size);
            }
        }
    }
    &:before {
        transform: translateY(-50%);
        transition: all ease .3s;
    }
    &[type=reset] {
        &:before {
            content: "\e002";
        }
    }
    &[type=submit] {
        &:before {
            content: "\e057";
        }
    }
    &:hover,
    &:focus {
        text-decoration: none;
        background: $color-1--3;
        color: $color-1--1;
        &:before {
            color: $color-1--1;
        }
        > svg {
            fill: $color-1--1;
        }
    }
    // Big
    &.button-1--big {
        // Change font size reference
        font-size: #{$button-1--big__font-size}em;
        padding: em(1.9, $button-1--big__font-size) em(4, $button-1--big__font-size) em(1.9, $button-1--big__font-size) em(5, $button-1--big__font-size);
        &:before {
            left: em(2.4, $button-1--big__font-size);
            font-size: 1.9rem;
        }
    }
    // Small
    &.button-1--small {
        // Change font size reference
        font-size: #{$button-1--small__font-size}em;
        padding: em(.7, $button-1--small__font-size) em(2.2, $button-1--small__font-size) em(.7, $button-1--small__font-size) em(3.2, $button-1--small__font-size);
        &:before {
            left: em(1.5, $button-1--small__font-size);
            font-size: 1.2rem;
        }
    }
    // No icon
    &.button-1--no-icon {
        padding-left: em(2, $button-1__font-size);
        padding-right: em(2, $button-1__font-size);
        &:before {
            content: "";
            margin: 0;
        }
    }
    // No text
    &.button-1--no-text {
        text-align: left;
        text-indent: -9999px;
        white-space: nowrap;
        position: relative;
        padding: em(1.3, $button-1__font-size) em(1.9, $button-1__font-size);
        &:before {
            text-indent: 0;
            line-height: 0;
            display: block;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            margin: 0;
        }
    }
    // With svg
    &.button-1--svg {
        padding: em(1.4, $button-1__font-size) em(1.3, $button-1__font-size);
    }
    > svg {
        fill: $color-white;
        width: em(1.2, $button-1__font-size);
        height: em(1.2, $button-1__font-size);
        vertical-align: middle;
    }
    // With calendar font-icon
    &.button-1--calendar {
        &:before {
            left: em(1, 1.4);
            content: "\e05c";
        }
    }
    // With next icon
    &.button-1--next {
        &:before {
            content: "\e027";
        }
    }
    &--variation-1 {
        color: $color-white;
        background: $color-1--1;
        &:before {
            color: $color-white;
            content: '\e057';
        }
        > svg {
            fill: $color-white;
        }
    }
    &--variation-2 {
        color: $color-white;
        background: $color-2--1;
        &:before {
            color: $color-white;
            content: '\e057';
        }
        > svg {
            fill: $color-white;
        }
        &:hover,
        &:focus {
            background-color: $color-2--2;
        }
    }
}

//  @name Extenral link for button-1
a.button-1[href^="http://"]:not([href*="#{$domain}"]),
a.button-1[href^="https://"]:not([href*="#{$domain}"]),
a.button-1[href^="//"]:not([href*="#{$domain}"]) {
    @include default-icons-after('\e086', 0 0 0 .5rem, .8rem, inherit, middle);
    &:after {
        //transition: all ease .3s;
    }
    &:hover, &:focus {
        &:after {
            color: inherit;
        }
    }
}

a.button-1.button-1--variation-1[href^="http://"]:not([href*="#{$domain}"]),
a.button-1.button-1--variation-1[href^="https://"]:not([href*="#{$domain}"]),
a.button-1.button-1--variation-1[href^="//"]:not([href*="#{$domain}"]) {
    &:after {
        color: inherit;
    }
}

// @name Grey form
// @description Form with grey background
.form--3 {
    // @name button-1 (light)
    /*.button-1 {
        background: $color-white;
        &:hover, &:focus {
            background: $color-1--3;
            text-decoration: none;
        }
    }
    .button-1.button-1--variation-1 {
        background: $color-1--2;
        &:hover, &:focus {
            background: $color-1--3;
            text-decoration: none;
        }
    }*/
}
