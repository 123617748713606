// @name List type 4
// @description List for FAQ

.list-type-4 {
    // @name Dropdown menu
    .ddm {
        .ddm__sub-level {
            position: static;
            top: inherit;
            right: inherit;
            z-index: inherit;
            display: block;
            height: auto;
            overflow: hidden;
            max-height: 0;
            transition: max-height ease .2s;
        }
        &.ddm--active {
            .ddm__title {
                button {
                    &:before {
                        content: "\e001";
                        background: $color-1--2;
                        color: $color-white;
                    }
                }
            }
            .ddm__sub-level {
                max-height: 100em;
                transition: max-height ease 1s;
            }
        }
    }
    .ddm__title {
        button {
            text-align: left;
            font-size: 1em;
            display: block;
            width: 100%;
            padding-left: 6em;
            min-height: 4.2em;
            white-space: normal;
            @include default-icons-absolute-before('\e000', 1.4rem, $color-2--2, 0, inherit, inherit, 0);
            &:before {
                z-index: 2;
                background: $color-3--1;
                padding: 1em;
                transition: all ease .5s;
            }
        }
    }

    .list-type-4__title {
        font-family: $typo-3;
        font-size: 1.6em;
        font-weight: $bold;
        color: $color-black;
    }

    .list-type-4__item {
        padding: 3em 0;
        border-bottom: 1px solid darken($color-3--1, 20%);
        &:first-child {
            padding-top: 0;
        }
    }

    .list-type-4__content {
        padding: 1em 0 0 6em;
    }
}
