// @name Main menu
.menu-main {
    background: $color-1--2;
    color: $color-white;
    ul {
        text-align: center;
    }
    a {
        color: $color-white;
        font-size: 1.3em;
        text-transform: uppercase;
        display: block;
        padding: 1rem 1rem;
    }
    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 .5rem;
    }
}
