.agenda {
    margin: 3rem 0 5.5rem;
    &__wrap {
        margin: 3rem auto 0;
        display: flex;
        justify-content: space-between;
        @include breakpoint(small down) {
            flex-direction: column;
            align-items: center;
        }
    }
    &__item {
        padding: 0 6rem;
        flex: 1;
        position: relative;
        @include breakpoint(medium down) {
            padding: 0 4rem;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
        @include breakpoint(small down) {
            padding: 0 1rem!important;
            text-align: center;
            margin-bottom: 5.5rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &:after {
            width: 0.1rem;
            height: 120%;
            display: block;
            content: '';
            position: absolute;
            top: -10%;
            right: 0;
            background-color: #4a4a4a;
            transform: rotate(35deg);
            @include breakpoint(small down) {
                top: inherit;
                bottom: -85%;
                right: 50%;
                transform: rotate(90deg) translateX(-50%);
            }
        }
        .category {

        }
        .title {
            font-size: 1.6rem;
            font-weight: $normal;
            color: $color-3--4;
            a {
                color: $color-3--4;
            }
        }
        &:last-child {
            &:after {
                content: none;
            }
        }
    }
}
