.go-to-top {
    display: block;
    position: relative;
    right: 1rem;
    bottom: 0;
    z-index: 7000;
    p {
        margin-top: -5rem;
        position: absolute;
        right: 0;
        text-align: right;
    }
    a {
        width: 5rem;
        height: 5rem;
        background-color: $color-1--1;
        &:before {
            font-weight: $light;
            font-size: 3.5rem;
            content: "\e00c";
        }
    }
}
