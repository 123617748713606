// @name Main stratis
.menu-stratis__opener {
    position: fixed;
    z-index: 9999;
    bottom: 2rem;
    left: 2rem;
    padding: .5rem 1rem;
    background: $color-white
}
$menu-stratis__font-size: 1.2;
.menu-stratis {
    display: none;
    background: $color-1--2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    width: 100%;
    font-size: #{$menu-stratis__font-size}em;
    @include breakpoint(medium down) {
        display: none;
    }
    &.menu-stratis--show {
        display: block;
    }
    abbr {
        border: 0;
    }
    li {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        a {
            display: block;
            padding: em(.8, $menu-stratis__font-size) em(.6, $menu-stratis__font-size);
            text-decoration: none;
        }
    }
    .menu-stratis__level-1 {
        @extend .wrapper-main;
        > li {
            width: auto;
            margin-bottom: 0;
            > a {
                color: color-contrast($color-1--2);
                transition: all ease .3s;
                display: inline-block;
                vertical-align: middle;
            }
            > a:hover,
            > a:focus,
            &.active > a {
                background: $color-black;
                color: color-contrast($color-black);
            }
            button {
                font-size: 1.3rem;
                display: inline-block;
                vertical-align: middle;
                color: $color-white;
                padding: .5rem 1rem;
                margin-right: .8rem;
                margin-left: -.4rem;
                position: relative;
                text-align: left;
                text-indent: -9999px;
                transition: background ease .3s;
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 8px 5.5px 0 5.5px;
                    border-color: $color-white transparent transparent transparent;
                }
                &:hover, &:focus {
                    background: $color-white;
                    &:before {
                        border-color: $color-1--2 transparent transparent transparent;
                    }
                }
                &:focus {
                    outline: 1px dotted $color-white;
                }
            }
        }
    }
    .menu-stratis__level-2 {
        box-shadow: .2rem .2rem .4rem $color-3--2;
        position: absolute;
        z-index: 101;
        top: -9999px;
        left: 0;
        font-size: em(1.2, $menu-stratis__font-size);
        width: em(20, $menu-stratis__font-size);
        background: $color-white;
        border-bottom: 0;
        text-align: left;
        padding: 10px;
        @include breakpoint(medium down) {
            display: none;
        }
        &.menu-stratis__level-2--show {
            top: em(3.1, $menu-stratis__font-size);
            display: block;
        }
        > li {
            display: block;
            float: none;
            border-bottom: 1px solid $color-3--2;
            &:last-child {
                border-bottom: 0;
            }
            > a {
                font-weight: $normal;
                color: color-contrast($color-white);
                transition: all ease .3s;
            }
            ul,
            &.act ul {
                margin: 0 0 10px 10px;
                background: $color-3--1;
                li {
                    border-bottom: 1px solid $color-white;
                    display: block;
                    a {
                        color: color-contrast($color-3--1);
                    }
                }
            }
            a:hover,
            a:focus,
            &.act > a,
            &.active > a {
                background: $color-1--2;
                color: color-contrast($color-1--2);
            }
        }
    }
}
