//@name Page content

// Select first content element and remove margin-top
.section-main__content span.anchor:first-of-type + .colPos0 .ce-bodytext > *:first-child,
.section-main__content span.anchor:first-of-type + .colPos0 > *:first-child,
.encadre > *:first-child,
    // content in click and roll
.rte.ddm__sub-level > *:first-child,
.ddm__sub-level span.anchor:first-of-type + .colPos18181 .ce-bodytext > *:first-child,
    // Single view
.heading--single + .section-main__wrapper .section-main__content > .rte > *:first-child {
    margin-top: 0;
}

// @name Title h2
$content-h2__font-size: 3.4;
.rte h2,
.rte .h2,
.h2 {
    font-family: $typo-1;
    font-size: #{$content-h2__font-size}em;
    line-height: 1.2;
    font-weight: $bold;
    color: $color-1--2;
    margin: em(6, $content-h2__font-size) 0 em(2.5, $content-h2__font-size) 0;
    a {
        color: $color-1--2;
        &:hover, &:focus {
            color: $color-black;
        }
    }
    @include breakpoint(small down) {
        font-size: 2.2rem;
    }
}

// @name Title h3
$content-h3__font-size: 2.5;
.rte h3,
.rte .h3,
.h3 {
    font-family: $typo-1;
    font-size: #{$content-h3__font-size}em;
    line-height: 1.2;
    font-weight: $bold;
    color: $color-1--1;
    margin: em(5, $content-h3__font-size) 0 em(1.7, $content-h3__font-size) 0;
    a {
        color: $color-1--1;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
    @include breakpoint(small down) {
        font-size: 1.8rem;
    }
}

// @name Title h4
$content-h4__font-size: 2;
.rte h4,
.rte .h4,
.h4 {
    font-family: $typo-1;
    font-weight: $bold;
    font-size: #{$content-h4__font-size}em;
    line-height: 1.2;
    color: $color-3--5;
    margin: em(4, $content-h4__font-size) 0 em(1.8, $content-h4__font-size) 0;
    a {
        color: $color-3--5;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
    @include breakpoint(small down) {
        font-size: 1.6rem;
    }
}

// @name Title h5
$content-h5__font-size: 1.7;
.rte h5,
.rte .h5,
.h5 {
    font-family: $typo-3;
    font-size: #{$content-h5__font-size}em;
    line-height: 1.2;
    color: $color-3--3;
    margin: em(4, $content-h5__font-size) 0 em(1.8, $content-h5__font-size) 0;
    a {
        color: $color-3--3;
        &:hover, &:focus {
            color: $color-black;
        }
    }
}

// @name Title h6
$content-h6__font-size: 1.5;
.rte h6,
.rte .h6,
.h6 {
    font-family: $typo-3;
    font-size: #{$content-h6__font-size}em;
    line-height: 1.2;
    color: $color-black;
    margin: em(2, $content-h6__font-size) 0 em(1, $content-h6__font-size) 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

$content-list__font-size: 1.4;
.rte {
    // @name Link
    a {
        color: $color-2--1;
        text-decoration: underline;
        &:hover, &:focus {
            text-decoration: none;
        }
    }

    //  @name External link
    a[href^="http://"]:not([href*="#{$domain}"]),
    a[href^="https://"]:not([href*="#{$domain}"]),
    a[href^="//"]:not([href*="#{$domain}"]) {
        @include default-icons-after('\e06d', 0 0 0 .5rem, 1.4rem, inherit, middle);
    }

    // @name Paragraph
    $content-paragraph__font-size: 1.4;
    $content-paragraph__font-size-mobile: 1.4;
    p {
        color: $color-3--4;
        font-size: #{$content-paragraph__font-size}em;
        font-weight: $light;
        margin: 0 0 em(1, $content-paragraph__font-size) 0;
        @include breakpoint(medium down) {
            margin: em(.5, $content-paragraph__font-size-mobile) 0 em(1, $content-paragraph__font-size-mobile) 0;
        }
    }

    // @name Content chapeau
    // @description Specific style for paragraph
    .chapeau {
        font-size: 1.5em;
        color: $color-3--3;
        a {
            color: $color-3--3;
        }
    }

    // @name Content encadre
    // @description Specific style for paragraph
    $encadre__font-size: 1.6;
    .encadre {
        padding: em(2.5, $encadre__font-size) em(1, $encadre__font-size) em(2, $encadre__font-size) em(3.5, $encadre__font-size);
        background: $color-1--2;
        margin: em(2.2, $encadre__font-size) em(4.9, $encadre__font-size) em(2.2, $encadre__font-size) em(2.1, $encadre__font-size);
        @include breakpoint(small down) {
            width: 90%;
            margin: 2.5rem auto;
        }
    }
    p.encadre,
    .encadre p,
    a.encadre,
    .encadre a {
        color: $color-white;
        font-size: #{$encadre__font-size}rem;
        font-weight: $light;
    }

    // @name Blockquote
    $content-blockquote__font-size: 2;
    $content-blockquote-icon-1__font-size: 4.6;
    blockquote {
        font-size: #{$content-blockquote__font-size}em;
        @include default-icons-absolute-before('\e06f', em($content-blockquote-icon-1__font-size, $content-blockquote__font-size), $color-2--1, em(-1.1, $content-blockquote-icon-1__font-size), inherit, inherit, em(-3, $content-blockquote-icon-1__font-size));
        &:before {
            font-style: normal;
        }
        padding: em(0.5, $content-blockquote__font-size) 0 em(0.5, $content-blockquote__font-size) em(2, $content-blockquote__font-size);
        font-weight: $normal;
        margin: em(2.5, $content-blockquote__font-size) em(4.9, $content-blockquote__font-size) em(1.5, $content-blockquote__font-size) em(6, $content-blockquote__font-size);
        color: $color-2--1;
        p {
            font-size: 1em;
            font-weight: $normal;
        }
        @include breakpoint(small down) {
            margin: em(2.5, $content-blockquote__font-size) em(2, $content-blockquote__font-size) em(1.5, $content-blockquote__font-size) em(3.5, $content-blockquote__font-size);
            &:before {
                top: 0;
            }
        }
    }

    // @name Unordered list
    ul,
    .show-hide__content ul {
        margin: 1.5rem 0 1.5rem 2.5rem;
        list-style: none;
        font-size: #{$content-list__font-size}rem;
        color: $color-3--5;
        padding: 0;
        p,
        a {
            font-size: #{$content-paragraph__font-size}rem;
            color: $color-3--6;
            text-decoration: none;
        }
        > li {
            margin-top: 0.8rem;
            @include default-icons-absolute-before('\e017', 1rem, $color-2--1, .4rem, inherit, inherit, 0);
            padding-left: 1.2rem;
            > ul:first-child, > * + ul { // for specificity selector
                margin: 0 0 1rem .8rem;
                list-style: none;
                padding: .1rem 0 .1rem 0;
                > li {
                    @include default-icons-absolute-before('\e007', 1rem, $color-3--6, .5rem, inherit, inherit, 0);
                    margin-top: 0;
                }
            }
            > ol:first-child, > * + ol { // for specificity selector
                margin: 0 0 1rem 1.5rem;
                padding: .1rem 0 .3rem 0;
                > li {
                    padding: 0;
                    &:before {
                        content: counter(item) '.';
                        counter-increment: item;
                        color: $color-3--3;
                        padding-right: 5px;
                        font-family: $typo-1;
                        font-weight: $semibold;
                        font-size: 1em;
                    }
                }
            }
            > ol,
            > ul {
                > li {
                    margin-top: 0;
                }
            }
        }
    }

    // @name Ordered list
    ol,
    .show-hide__content ol {
        margin: 1.5rem 0 1.5rem 2.5rem;
        font-size: #{$content-list__font-size}rem;
        list-style-type: none;
        counter-reset: item;
        p,
        a {
            color: $color-3--6;
            text-decoration: none;
            font-size: #{$content-paragraph__font-size}rem;
        }
        > li {
            margin-top: 0.8rem;
            padding: 0;
            &:before {
                content: counter(item) '.';
                counter-increment: item;
                color: $color-2--2;
                padding-right: 5px;
                font-weight: $semibold;
            }
            > ol:first-child, > * + ol { // for specificity selector
                margin: 0 0 1rem 1.5rem;
                padding: .1rem 0 .1rem 0;
                > li {
                    margin-top: 0;
                    padding-left: 0.3rem;
                    &:before {
                        content: counter(item) '.';
                        counter-increment: item;
                        color: $color-3--3;
                        padding-right: 5px;
                        font-family: $typo-1;
                        font-weight: $semibold;
                        font-size: 1em;
                    }
                }
            }
            > ul:first-child, > * + ul { // for specificity selector
                margin: 0 0 1rem;
                list-style: none;
                padding: .1rem 0 .3rem 1.7rem;
                > li {
                    @include default-icons-absolute-before('\e06c', 0.5rem, $color-black, 0.7rem, inherit, inherit, 0);
                }
            }
            > ol,
            > ul {
                > li {
                    margin-top: 0;
                }
            }
        }
    }

    // @name Reset list
    // @description for reset list ul or ol in content
    .reset__list {
        margin: auto;
        padding: 0;
        list-style: none;
        font-size: 1em;
        > li {
            padding: 0;
            &:before {
                content: "";
                margin: auto;
            }
            > ol, > ul {
                padding: 0;
                > li {
                    padding: 0;
                    &:before {
                        content: "";
                        margin: auto;
                    }
                }
            }
        }
    }

    // @name Table
    .table-wrapper {
        margin: 4em 0;
        @include breakpoint(medium down) {
            position: relative;
        }
    }
    .table-wrapper-fade {
        @include breakpoint(medium down) {
            position: absolute;
            right: 0;
            width: 30px;
            height: 100%;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 100%);
        }
    }
    .table-wrapper-inner {
        max-width: #{($tablet/10) - $column-bloc__width - $column-bloc__padding-left}em;
        overflow-y: auto;
        width: 100%;
        @include breakpoint(medium down) {
            max-width: #{($smartphone/10) - $column-bloc__width - $column-bloc__padding-left}em;
        }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
    }
    table {
        width: 100%;
        font-size: 1em;
        caption {
            $table__caption_font-size: 1.2;
            font-family: $typo-1;
            text-align: right;
            font-weight: $light;
            font-size: #{$table__caption_font-size}em;
            color: $color-3--4;
            margin-bottom: em(0.5, $table__caption_font-size);
            &:after {
                margin-left: 0.3rem;
                content: '\e024';
                color: $color-1--1;
                font-size: 1rem;
                font-family: 'icons-default';
            }
        }
        th, td {
            padding: 12px 14px;
            ul {
                font-size: 1em;
            }
            p {
                font-size: #{$content-paragraph__font-size/1.3}em;
            }
        }
        th {
            font-size: 1.4em;
            text-transform: uppercase;
            font-family: $typo-3;
            font-weight: $bold;
            color: $color-white;
            text-align: left;
            background-color: $color-1--3;
            border-top: 1px solid $color-white;
            border-right: 1px solid $color-white;
        }
        thead th {
            font-size: 1.3em;
            background: $color-white;
            font-weight: $bold;
            color: $color-3--4;
            border-bottom: 4px solid $color-1--1;
        }
        tbody {
            tr:first-child {
                th, td {
                    border-top: 0;
                }
            }
            th {
                font-weight: $light;
                text-align: right;
            }
            &:before {
                content: '';
                background: #ffffff;
                width: 100%;
                height: 1px;
                display: block;
            }
        }
        td {
            font-size: 1.3em;
            color: $color-white;
            background: $color-1--2;
            vertical-align: top;
            border-top: 1px solid $color-white;
        }
    }

    // @name column 2
    .column--2,
    .column--3 {
        margin: 4em 0 2em;
        column-gap: 2em;
    }
    .column--2 {
        columns: 2;
    }
    .column--3 {
        columns: 3;
    }
}
