.offre {
    padding: 2rem 0 3rem;
    @include breakpoint(medium down) {
        padding-bottom: 5.5rem;
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        @include breakpoint(medium down) {
            flex-direction: column;
        }
    }
    &__tabs {
        flex: 1;
        &-btn {
            margin: 4rem 0;
            @include breakpoint(small down) {
                text-align: center;
                margin-top: 2rem;
            }
            li {
                display: inline-block;
                margin-right: 1rem;
                @include breakpoint(small down) {
                    display: block;
                    margin: 0.1rem auto;
                }
                .button-1 {
                    width: 16.9rem;
                    font-size: 1.3rem;
                    font-weight: $light;
                    text-align: center;
                    &:hover,
                    &:focus {
                        font-weight: $bold;
                        color: $color-black;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
                &.current {
                    .button-1 {
                        background-color: $color-1--3;
                        font-weight: $bold;
                        color: $color-black;
                    }
                }
            }
        }
        &-wrap {
            label {
                margin-top: 3rem;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                text-align: right;
                font-size: 1.3rem;
                @include breakpoint(medium down) {
                    padding-right: 17rem;
                }
                @include breakpoint(small down) {
                    margin-top: 2rem;
                    align-items: flex-start;
                    text-align: left;
                    padding-right: 0;
                    flex-direction: column;
                }
                span {
                    padding-right: 2rem;
                    @include breakpoint(medium down) {
                        flex-shrink: 0;
                    }
                    @include breakpoint(small down) {
                        margin-bottom: 2rem;
                        padding-right: 0;
                    }
                }
                input,
                select {
                    padding-left: 0.1rem;
                    width: 65%;
                    @include breakpoint(medium down) {
                        width: calc(100% - 15.1rem);
                    }
                    @include breakpoint(small down) {
                        width: 100%;
                    }
                }
                input {
                    margin-top: 0;
                    @include placeholder() {
                        color: $color-3--3;
                    }
                }
                select {
                    color: #6b6b6b;
                }
            }
            .button-1 {
                margin-top: 3rem;
                margin-left: 35%;
                @include breakpoint(medium down) {
                    margin: -4.6rem 3rem 0 0;
                    float: right;
                }
                @include breakpoint(small down) {
                    margin-top: 2.5rem;
                    float: none;
                }
            }
        }
        &-item:not(:first-child) {
            display: none;
        }
    }
    &__list {
        width: 30rem;
        margin-left: 4rem;
        @include breakpoint(medium down) {
            width: 100%;
            margin-left: 0;
        }
        ul {
            width: 95%;
            padding: 1.5rem 2.5rem;
            background-color: $color-1--1;
            @include breakpoint(medium down) {
                width: 100%;
                display: flex;
                justify-content: space-around;
            }
            @include breakpoint(medium down) {
                flex-direction: column;
            }
            li {
                padding: 0.8rem 0 0.8rem 3rem;
                color: $color-white;
                text-transform: uppercase;
                font-size: 1.2rem;
                font-weight: $bold;
                position: relative;
                @include breakpoint(medium down) {
                    display: inline-block;
                }
                &:before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 0.35rem 0.5rem;
                    background-color: $color-1--3;
                    border-radius: 50%;
                    content: '\e017';
                    font-family: 'icons-default';
                }
                a {
                    color: $color-white;
                }
            }
        }
    }
}
