.bloc-rapides {
    &__item {
        a {
            margin: 1rem 0;
            padding: 0.7rem 0 0.7rem 3.5rem;
            display: block;
            position: relative;
            font-size: 1.4rem;
            font-weight: $light;
            color: $color-3--4;
            svg {
                max-width: 2.2rem;
                max-height: 2.2rem;
                position: absolute;
                left: 0;
                top: 0;
                fill: $color-1--1;
            }

        }
        &:last-child {
            a {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }
}
