.service-detail {
    h2 {
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        font-size: 2.4rem;
        font-weight: $normal;
        border-bottom: 1px solid #e8e7e7;
        color: $color-1--1;
        @include breakpoint(medium down) {
            font-size: 3.2rem;
        }
        @include breakpoint(small down) {
            font-size: 2rem;
            margin-bottom: 0!important;
        }
    }
    h3 {
        font-size: 1.8rem;
        color: $color-3--4;
        font-weight: $normal;
    }
    &__gallery {
        //display: flex;
        @extend .clear-fix;
        @include breakpoint(medium down) {
            flex-direction: column-reverse;
        }
        h2 {
            margin-top: 0;
            margin-bottom: 3rem;
            border-bottom: 0;
        }
    }
    &__specialties {
        display: flex;
        @include breakpoint(small down) {
            flex-direction: column;
        }
        ul {
            flex: 1;
            @include breakpoint(small down) {
                margin-bottom: 0;
                margin-top: 0;
            }
            li {
                padding-left: 1.5rem;
                &:before {
                    top: 0.3rem;
                    font-size: 1.2rem;
                    color: $color-1--1;
                    content: '\e027';
                }
                a {
                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &__stay {
        h3 {
            margin-top: 2rem;
            margin-bottom: 2.5rem;
        }
        a {
            color: $color-3--4;
        }
    }
}

.contacts {
    h2 {
        border-bottom: 0;
    }
    &__wrap {
        display: flex;
        padding: 3rem;
        background-color: $color-1--1;
        @include breakpoint(small down) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
    &__item {
        flex: 1;
        @include breakpoint(small down) {
            margin-bottom: 2rem;
            text-align: center;
        }
        &-title {
            color: $color-white;
            font-size: 1.6rem;
        }
        .list-infos__item {
            @include breakpoint(small down) {
                padding-left: 0;
            }
            &:before {
                color: $color-white;
                @include breakpoint(small down) {
                    position: static;
                    margin-right: 0.5rem;
                }
            }
            a {
                color: $color-white;
                text-decoration: none;
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
}

$service-details__size: 1;

.person {
    width: 50%;
    float: left;
    margin-bottom: 2em;
    @extend .clear-fix;
    @include breakpoint(small down) {
        width: 100%;
        float: none;
    }
    &__foto {
        width: em(6.6, $service-details__size);
        float: left;
        img {
            width: 100%;
            display: block;
        }
    }
    &__descript {
        margin-left: em(9.5, $service-details__size);
        p {
            font-size: em(1.3, $service-details__size);
            line-height: normal;
        }
    }
    &__name {
        font-size: em(1.3, $service-details__size);
        font-weight: $bold;
        color: $color-black;
        line-height: normal;
        padding-left: em(1.3, $service-details__size);
        @include default-icons-absolute-before("\e017", em(1, $service-details__size), $color-1--1, 50%, inherit, inherit, 0);
        &:before {
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
}

.consultations {
    &__wrap {
        //padding: 2.5rem 3.5rem;
        display: flex!important;
        @include breakpoint(small down) {
            flex-direction: column;
        }
    }
    &__item {
        padding: 0.5rem 0 0 2rem ;
        flex: 1;
        @include breakpoint(small down) {
            &:first-child {
                margin-bottom: 1rem;
            }
        }
    }
    .title {
        font-size: 1.7rem;
        font-weight: $normal;
        text-transform: none;
        color: $color-1--1;
        margin-bottom: 0.7rem;
    }
    .phone {
        padding-left: 1.5rem;
        font-size: 1.3rem;
        font-weight: $normal;
        color: $color-3--4;
        text-decoration: none;
        @include default-icons-absolute-before("\e017", em(1, $service-details__size), $color-1--1, 50%, inherit, inherit, 0);
        &:hover {
            text-decoration: underline;
        }
        &:before {
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
}

.expertise {
    h3 {
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #e8e7e7;
    }
}

.utiles {
    margin-top: 6rem;
    margin-bottom: 3.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include breakpoint(small down) {
        flex-direction: column;
    }
    &__item {
        flex: 0 1 46%;
        @include breakpoint(small down) {
            width: 100%;
            &:first-child {
                margin-bottom: 4rem;
            }
        }
        p {
            font-size: em(1.4, $service-details__size);
            margin-bottom: 20px;
        }
    }
    .title {
        font-size: em(2.4, $service-details__size);
        color: $color-1--2;
        font-weight:$normal;
        line-height: normal;
        border-bottom: 1px solid #e8e7e7;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }
    li {
        border-top: 1px solid #e8e7e7;
        padding: 10px 0;
        &:last-child {
            border-bottom: 1px solid #e8e7e7;
        }
        a, span {
            display: inline-block;
            font-size: em(1.4, $service-details__size);
            color: $color-black;
            margin-left: 2px;
        }
        a {
            text-decoration: underline;
            padding-left: em(1.3, $service-details__size);
            @include default-icons-absolute-before("\e017", em(1, $service-details__size), $color-1--1, 50%, inherit, inherit, 0);
            &:before {
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
}
