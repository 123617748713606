// Galerie classique
.classical-gallery {
    max-width: 70rem;
    @extend .clear-fix;
    .service-detail__gallery & {
        width: 46rem;
        max-width: 46rem;
        padding-right: 4rem;
        float: left;
        @include breakpoint(medium down) {
            width: inherit;
            max-width: 70rem;
            padding-right: 0;
            margin-top: 3rem!important;
        }
    }
}

.classical-gallery__title {
    $classical-gallery__title_font-size: 1.6;
    font-family: $typo-2;
    text-align: right;
    font-weight: $normal;
    font-size: #{$classical-gallery__title_font-size}em;
    color: $color-3--3;
    margin: em(3, $classical-gallery__title_font-size) 0 em(1, $classical-gallery__title_font-size) 0;
}

.classical-gallery {
    margin: 4em 0 7em;
    @include breakpoint(small down) {
        // Galerie classique (avec vignettes) + Galerie album
        margin-left: -10px;
        margin-right: -10px;
    }
    @include breakpoint(medium down) {
        position: relative;
    }
    &__title {
        @include breakpoint(medium down) {
            padding: 0 10px;
        }
    }
    &__nb-items {
        display: none;
        @include breakpoint(medium down) {
            display: block;
            width: 5rem;
            height: 5rem;
            padding: 1.4rem 0;
            background: $color-1--1;
            color: $color-white;
            position: absolute;
            z-index: 0;
            bottom: 0;
            right: 0;
            font-size: 1.6rem;
            margin: 0;
            text-align: center;
        }
    }
}

.slider-galerie {
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 10px;
    @include breakpoint(medium down) {
        margin-bottom: 0;
    }
    .slider-galerie__item {
        width: 100%;
        height: 100%;
        z-index: 1 !important;
        border: none;
        background: $color-3--1;
        @include breakpoint(medium down) {
            height: auto;
            display: none;
            &:first-child {
                display: block;
            }
        }
    }
    .infos-img {
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 0;
        width: calc(100% - 500px);
        display: table;
        padding: 10px;
        font-family: $typo-2;
        font-weight: $light;
        font-style: italic;
        color: $color-3--3;
        font-size: 1.3em;
        white-space: normal;
        @include breakpoint(medium down) {
            display: none;
            position: static;
            right: inherit;
            bottom: inherit;
            background: #000;
        }
        &__nbItems {
            display: table-cell;
            vertical-align: bottom;
            white-space: nowrap;
            width: 70px;
        }
        &__wrap {
            display: table-cell;
            vertical-align: top;
            position: relative;
            padding-left: 1.5rem;
            &:before {
                position: absolute;
                top: 0.3rem;
                left: 0;
                content: '\e024';
                color: $color-1--1;
                font-size: 1rem;
                font-family: 'icons-default';
                font-style: normal;
            }
        }
        &__legend {
            display: block;
        }
        .service-detail__gallery & {
            width: calc(100% - 300px);
            padding-left: 0;
            font-size: 1.1rem;
        }
    }
    a {
        display: block;
        width: 100%;
        height: auto;
        text-align: center;
        position: relative;
        white-space: nowrap;
        padding: 68.08510% 0 0 0; // 100/(940/640)
        @include breakpoint(medium down) {
            display: inline-block;
            text-decoration: none;
            padding: 0;
        }
    }
    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 50%;
        right: 0;
        transform: translateX(-50%) translateY(-50%);
        @include breakpoint(medium down) {
            max-height: inherit;
            position: static;
            top: inherit;
            bottom: inherit;
            left: inherit;
            right: inherit;
            transform: inherit;
            display: inline;
        }
    }
}

.carousel-galerie {
    width: 500px;
    display: inline-block;
    text-align: right;
    position: relative;
    float: right;
    z-index: 1;
    .item {
        border: none;
    }
    .service-detail__gallery & {
        width: 30rem;
    }
}

.carousel-galerie__thumb {
    width: 340px;
    height: 74px;
    z-index: 2;
    margin-left: 80px;
    text-align: left;
    @include breakpoint(medium down) {
        display: none;
    }
    &.no-carousel {
        position: relative;
        .carousel-galerie__item {
            display: inline-block;
            margin: 0 3px;
        }
    }
    .service-detail__gallery & {
        width: 20rem;
        height: 4.5rem;
        margin-left: 5rem;
    }
}

.carousel-galerie__item {
    z-index: 1 !important;
    text-align: center;
    &.cycle-slide-active button, &.cycle-pager-active button, button:hover, button:focus {
        &:before {
            background: rgba(0, 0, 0, 0);
            @include background-opacity($color-1--1, .4)
        }
    }
    button {
        position: relative;
        &:before {
            transition: all ease .2s;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0);
        }
        img {
            width: 74px;
            height: 74px;
            .service-detail__gallery & {
                width: 4.5rem;
                height: 4.5rem;
            }
        }
    }
}

.carousel-galerie__pager {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    height: 74px;
    width: 500px;
    z-index: 1;
    @include breakpoint(medium down) {
        display: none;
    }
    .service-detail__gallery & {
        width: 30rem;
    }
}

.carousel-galerie__prev, .carousel-galerie__next {
    position: absolute;
    top: 0;
    padding: 0;
    button {
        width: 74px;
        height: 74px;
        background: $color-1--1;
        text-indent: -9999px;
        transition: all ease .2s;
        @include default-icons-absolute-before('\e016', 2.6rem, $color-white, 50%, inherit, inherit, 50%);
        &:before {
            text-indent: 0;
            transform: translateY(-50%) translateX(-50%);
        }
        &:hover, &:focus {
            background: $color-1--3;
        }
        .service-detail__gallery & {
            width: 4.5rem;
            height: 4.5rem;
        }
    }
    &:before {
        content: "";
        speak: none;
    }
}

.carousel-galerie__prev {
    left: 0;
}

.carousel-galerie__next {
    right: 0;
    button {
        &:before {
            content: "\e017";
        }
    }
}
