// @name Signature stratis
.stratis {
    margin-top: 3rem;
    background: transparent;
    width: 100%;
    font-size: 1.1rem;
    color: $color-white;
    text-align: right;
    font-family: $typo-1;
    text-transform: uppercase;
    @include breakpoint(medium down) {
        margin-top: 2rem;
    }
    a {
        display: inline-block;
        padding: 6px 10px;
        margin-left: 1rem;
        color: $color-white;
        background: #c70000;
        text-decoration: none;
        font-weight: $bold;
        &:hover, &:focus {
            color: #A40015;
            background: $color-white;
        }
    }
}
