// @name Pie Chart
.answerChart {
    @extend .clear-fix;
}

#pieChartTarget {
    float: left;
    width: 300px;
    height: 300px;
    margin-right: 30px;
    @include breakpoint(small down) {
        float: none;
        margin: 0 auto;
    }
}

.rte .list-pie-chart {
    margin-left: 0;
    overflow: hidden;
    @include breakpoint(small down) {
        width: 100%;
        float: none;
    }
    > li {
        margin: 0.8em 0;
        padding-left: em(2.5, $content-list__font-size);
    }
}

$pie_colors: #d94766, #de7f93, #ef654e, #ed8c52, #febd4c, #f3e65e, #b4d75e, #57ba81, #97dab3, #50c3bc, #a0afb7, #306a96, #2d4c59, #3da4c6, #cfaee3, #9873ae;
@for $i from 1 through length($pie_colors) {
    $j: $i - 1; // Because $i doesn't begin by 0
    #pieChartTarget .pie#{$j} {
        fill: nth($pie_colors, $i);
    }
    .rte .list-pie-chart > .pie#{$j} {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            display: block;
            background: nth($pie_colors, $i);
        }
    }
}
