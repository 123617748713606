// Focus
a, button, input[type="button"] {
    &:focus {
        outline: 1px dashed $color-1--2;
    }
}

// @name link-block
.link-block {
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        content: '';
    }
}

// @name link-bloc
// @description Create an ergonomic link
.link-bloc__context {
    position: relative;
    .link-bloc {
        &:after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            content: "";
            speak: none;
            z-index: 10;
        }
    }
}

// @name Link view
// @description Link under feature (RSS of the feature + list of the feature)
.link-view {
    $link-view__item__font-size: 1.1;
    .button-1 {
        font-size: #{$link-view__item__font-size}em;
        padding: em(1.35, $link-view__item__font-size) em(1.9, $link-view__item__font-size) em(1.35, $link-view__item__font-size) em(3.23, $link-view__item__font-size);
        line-height: em(1, $link-view__item__font-size);
        &:before {
            left: 1.8rem;
            font-size: 1rem;
            content: "\e02c";
        }
        &:first-child {
            margin-bottom: 1rem;
        }
        &.button-1--no-icon {
            &:before {
                content: '';
            }
        }
    }
    &--agenda {
        .button-1 {
            &.button-1--no-icon {
                padding-left: em(2, $link-view__item__font-size);
                padding-right: em(2.9, $link-view__item__font-size);
            }
        }
    }
    &--news {
        .button-1 {
            padding: em(1.35, $link-view__item__font-size) em(1.5, $link-view__item__font-size) em(1.35, $link-view__item__font-size) em(3, $link-view__item__font-size);
            &:before {
                left: 1.5rem;
            }
            &.button-1--no-icon {
                padding-left: em(0.8, $link-view__item__font-size);
                padding-right: em(0.8, $link-view__item__font-size);
            }
        }
    }
    &--kiosque {
        margin-top: 2rem;
        @include breakpoint(medium down) {
            margin-top: 1rem;
        }
        @include breakpoint(small down) {
            text-align: center;
        }
        .button-1 {
            font-size: 1.2rem;
            font-weight: $normal;
            padding: 1.2rem 1rem 1.2rem 2rem;
        }
    }
}

.column-bloc {
    .link-view {
        margin-top: 2em;
        @include breakpoint(small down) {
            text-align: center;
        }
    }
}
