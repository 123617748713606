// @name Cross menu
.menu-cross {
    flex: 1;
    padding-top: 1rem;
    text-transform: uppercase;
    overflow: hidden;
    @include breakpoint(medium down) {
        padding-left: 2rem;
    }
    @include breakpoint(small down) {
        text-align: center;
        padding-left: 0;
    }
    li {
        vertical-align: top;
        width: 100%;
        padding: .2em 1.5em;
        @include default-icons-absolute-before('\e027', 1.1rem, $color-white, 0.3rem, inherit, inherit, 0);
        @include breakpoint(small down) {
            margin: 0.5rem 0;
            &:before {
                position: static;
                margin-right: 0.4rem;
                line-height: 1.2rem;
            }
        }
        a {
            display: block;
            color: $color-white;
            text-decoration: none;
            font-size: 1.2em;
            line-height: 1.2em;
            &:hover, &:focus {
                color: $color-1--3;
                span {
                    text-decoration: underline;
                }
            }
            @include breakpoint(small down) {
                display: inline-block;
            }
        }
        &.active a {
            color: $color-1--3;
            text-decoration: none;
            font-weight: $bold;
        }
    }
}
